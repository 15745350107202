/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'

export interface FunnelSliceState {
	linkData: ISharedLink | null
	rightTab: string
	isLoading: boolean
	error?: string | null
}

const initialState: FunnelSliceState = {
	linkData: null,
	rightTab: 'files',
	isLoading: false,
	error: null
}

export const sharedLikSlice = createAppSlice({
	name: 'sharedLinks',
	initialState,
	reducers: create => ({
		setSharedLink: create.reducer(
			(state, action: PayloadAction<ISharedLink | null>) => {
				state.linkData = action.payload
			}
		),
		setRightTab: create.reducer((state, action: PayloadAction<string>) => {
			state.rightTab = action.payload
		})
	}),
	selectors: {
		selectSharedLinkData: state => state.linkData,
		selectRightTab: state => state.rightTab,
		selectSharedLinkStatus: state => state.isLoading
	}
})

export const { setSharedLink, setRightTab } = sharedLikSlice.actions

export const { selectSharedLinkData, selectRightTab, selectSharedLinkStatus } =
	sharedLikSlice.selectors
